<template>
  <campaign-layout
    :showMenu="computed_debug"
    :pageTitle="title"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : testoPopover
    "
  >
    <template v-if="categories && categories.length">
      <transition-group
        name="block-slide-in"
        :style="{ '--total': categories.length }"
      >
        <template v-for="(category, i) in categories" :key="category.id">
          <ion-card
            :key="i"
            :style="{ '--i': i }"
            v-if="showList1 && showList2"
            @click="handleCategoryClick(category)"
          >
            <ion-card-header>
              <!--            <ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
              <div
                v-if="category.percentualeCompletamento >= 0"
                class="percentage"
              >
                {{ category.percentualeCompletamento }}%
              </div>
              <div class="title">
                <ion-card-title class="text-18">{{
                  category.name
                }}</ion-card-title>
              </div>
            </ion-card-header>

            <!--          <ion-card-content>-->
            <!--            Keep close to Nature's heart... and break clear away, once in awhile,-->
            <!--            and climb a mountain or spend a week in the woods. Wash your spirit clean.-->
            <!--          </ion-card-content>-->
          </ion-card>
        </template>
      </transition-group>
      <br />
      <!--      <hr>-->
      <br />
    </template>
    <template v-if="chapters && chapters.length">
      <template v-for="chapter in chapters" :key="chapter.id">
        <ion-card>
          <ion-card-header>
            <!--            <ion-card-subtitle>LOREM</ion-card-subtitle>-->
            <div class="lock-status">
              <ion-icon v-if="!chapter.isClosed" :icon="lockOpen"></ion-icon>
              <ion-icon v-if="chapter.isClosed" :icon="lockClosed"></ion-icon>
            </div>
            <ion-card-title class="classtitle text-20">{{
              chapter.title
            }}</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <template v-if="!chapter.isClosed">
              <template v-if="chapter.immagine_capitolo">
                <img :src="chapter.immagine_capitolo" @error="handleImgError" />
                <ion-button
                  v-if="chapter.documents[0].url"
                  class="button"
                  @click="$root.externalLinkBlank(chapter.documents[0].url)"
                  ><ion-icon :icon="downloadOutline"></ion-icon> Apri
                  allegato</ion-button
                >
              </template>
              <template v-else-if="chapter.id_tipo != 1">
                <div v-for="document in chapter.documents" :key="document.id">
                  <template
                    v-if="
                      document.url.includes('https://vimeo.com') &&
                      whappyClientConfig.name != 'Yoube'
                    "
                  >
                    <iframe
                      :src="
                        document.url.replace(
                          'https://vimeo.com/',
                          'https://player.vimeo.com/video/'
                        )
                      "
                      style="width: 100%"
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                    ></iframe>
                    <p style="white-space: pre-wrap">
                      {{ chapter.description }}
                    </p>
                  </template>
                  <template v-else>
                    <div
                      v-if="
                        document.url.includes('.pdf') &&
                        whappyClientConfig.name != 'Yoube'
                      "
                      class="iframe-container"
                    >
                      <iframe
                        :src="document.url"
                        frameBorder="0"
                        scrolling="auto"
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                    <img
                      v-else-if="whappyClientConfig.name != 'Yoube'"
                      :src="document.url"
                      @error="handleImgError"
                    />
                    <p style="white-space: pre-wrap">
                      {{ chapter.description }}
                    </p>

                    <div
                      v-if="$route.path.includes('20documenti%20digitali')"
                      class="w-full flex justify-content-between align-items-center"
                    >
                      <ion-button
                        class="button"
                        @click="$root.externalLinkBlank(document.url)"
                        ><ion-icon :icon="downloadOutline"></ion-icon> Apri
                      </ion-button>
                      <ion-button @click="showFirmaDigitale" class="button"
                        ><ion-icon :icon="pencil"></ion-icon> Firma</ion-button
                      >
                      <ion-alert
                        :is-open="firmaDigitaleVisible"
                        header="Firma Digitale"
                        message="Ti stiamo inviando un SMS per procedere alla firma"
                        :buttons="['Ok']"
                        @didDismiss="hideFirmaDigitale"
                      ></ion-alert>
                    </div>
                    <ion-button
                      v-else
                      class="button"
                      @click="$root.externalLinkBlank(document.url)"
                      ><ion-icon :icon="downloadOutline"></ion-icon> Apri
                    </ion-button>
                  </template>
                </div>
              </template>
              <template v-if="chapter.video_type_contenuto_formativo == 1">
                <template v-if="chapter.embed_contenuto_formativo">
                  <!-- <div
                    class="embed-video"
                    v-html="chapter.embed_contenuto_formativo"
                  ></div> -->
                  <div
                    class="embed-video"
                    v-html="chapter.embed_contenuto_formativo"
                  ></div>
                  <p style="white-space: pre-wrap">{{ chapter.description }}</p>
                </template>
              </template>
              <template v-if="chapter.video_type_contenuto_formativo == 2">
                <template v-if="chapter.cover_contenuto_formativo">
                  <p style="white-space: pre-wrap">{{ chapter.description }}</p>

                  <ion-button
                    class="button"
                    @click="
                      $root.externalLinkBlank(chapter.link_contenuto_formativo)
                    "
                    ><img
                      :src="chapter.cover_contenuto_formativo"
                      @error="handleImgError"
                  /></ion-button>
                </template>
                <template v-if="!chapter.cover_contenuto_formativo">
                  <p style="white-space: pre-wrap">{{ chapter.description }}</p>
                  <ion-button
                    v-if="chapter.link_contenuto_formativo"
                    expand="block"
                    @click="
                      $root.externalLinkBlank(chapter.link_contenuto_formativo)
                    "
                  >
                    {{ $t("chapter.open_learning_content") }}
                  </ion-button>
                </template>
              </template>
            </template>
            <template v-if="!chapter.isClosed">
              <div v-if="chapter.id_survey">
                <ion-button expand="block" @click="openChapter(chapter)">
                  <!-- {{ $t("chapter.role_play") }} -->
                  FAI IL TEST
                </ion-button>
                <!--                <p class="percentage-survey">{{ chapter.percentualeCompletamento }}% {{ $t("chapter.answered") }}</p>-->
                <p class="percentage-survey">
                  {{ chapter.RisposteEsatte }}/{{ chapter.RisposteTotali }}
                  {{ $t("chapter.answered") }}
                </p>
              </div>
              <div
                class="workshop"
                v-if="
                  chapter.liveInFuture ||
                  chapter.link_sessione_live ||
                  chapter.recorded_video_link
                "
              >
                <div>
                  <p
                    v-if="
                      chapter.liveInFuture &&
                      !(chapter.recorded_video && chapter.recorded_video_link)
                    "
                  >
                    {{ chapter.liveText }}
                  </p>
                  <p
                    class="islink"
                    v-if="
                      chapter.link_sessione_live && !chapter.recorded_video_link
                    "
                    @click="$root.externalLinkBlank(chapter.link_sessione_live)"
                  >
                    {{ $t("chapter.open_session_live") }}
                  </p>
                  <p
                    class="islink"
                    v-if="chapter.recorded_video_link"
                    @click="
                      $root.externalLinkBlank(chapter.recorded_video_link)
                    "
                  >
                    {{ $t("chapter.open_recorded_video") }}
                  </p>
                </div>
              </div>
            </template>
          </ion-card-content>
        </ion-card>
      </template>
      <ion-card>
        <ion-card-header>
          <div class="lock-status">
            <ion-icon :icon="lockClosed"></ion-icon>
          </div>

          <ion-card-content class="ion-no-padding">
            <PrenotaAppuntamento
              v-if="whappyClientConfig.name == 'Automotive SpA'"
              currentMentorAvatar="https://randomuser.me/api/portraits/men/3.jpg"
              :mentor="{
                name: 'Jon Doe',
                role: 'Admin',
                img: 'https://randomuser.me/api/portraits/men/3.jpg',
              }"
              currentDate="2022-12-31:17:00:00"
              title="Ora che sei pronto proponi il giorno e ora l'affiancamento con il tuo collega, riceverai il feedback in breve tempo"
              :immediateScroll="false"
            />
          </ion-card-content>
        </ion-card-header>
      </ion-card>
    </template>
  </campaign-layout>
</template>

<script>
import { arrowForward, downloadOutline, pencil } from "ionicons/icons";
import ApiService from "../../common/service.api";
import {
  dateToDayMonth,
  dateToHourMinute,
  translate,
} from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";
import CampaignService from "../../common/mixins/Campaign";
import moment from "moment";
import {
  IonIcon,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonicSafeString,
  IonAlert,
} from "@ionic/vue";
import { lockOpen } from "ionicons/icons";
import { lockClosed } from "ionicons/icons";
import { useQuizStore } from "@/stores/useQuizStore";
import PrenotaAppuntamento from "../pk_views/components/PrenotaAppuntamento.vue";
import { useGeneralStore } from "../../stores/useGeneralStore";
const quizStore = useQuizStore();

export default {
  name: "Learning",
  data() {
    return {
      title: "",
      downloadOutline,
      pencil,
      campaignData: {},
      categories: null,
      chapters: null,
      lockOpen,
      lockClosed,
      showList1: false,
      showList2: false,
      firmaDigitaleVisible: false,
      INFOPOPOVER_MAP: {
        Yoube:
          "In questa sezione trovi le informazioni e i materiali utili da scaricare, le domande più frequenti. Controlla i tuoi progress e completa i percorsi di Academy per ottenere dei premi!",
        default:
          "In questa sezione trovi le informazioni e i materiali utili da scaricare, le domande più frequenti. Controlla i tuoi progress e completa i percorsi di Academy per ottenere dei premi!",
      },
    };
  },
  components: {
    IonIcon,
    CampaignLayout,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
    PrenotaAppuntamento,
    IonAlert,
  },
  mixins: [CampaignService],
  methods: {
    showFirmaDigitale() {
      this.firmaDigitaleVisible = true;
    },
    hideFirmaDigitale() {
      this.firmaDigitaleVisible = false;
    },
    handleCategoryClick(category) {
      if (category.name === "Le tue survey sull'Onboarding") {
        this.$router.push({ name: "PKSurvey", params: { idSurvey: "19" } });
      } else {
        this.$router.push({
          name: "campaign_learning",
          params: {
            id: this.campaignId,
            parent: category.id,
            title: category.name,
          },
        });
      }
    },
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    loadCategories: function () {
      const route = this.$route;
      const { id, title } = route.params;
      this.title = title;
      const parentCategoryId = route.params.parent ? route.params.parent : 0;
      this.parentCategoryId = parentCategoryId;
      if (id) {
        ApiService.get("CampaignManager/GetUserLearningCategories/" + id, {
          // params: {idCampagna: id}
        }).then((res) => {
          // this.categories.splice(0)
          this.categories = res.data
            .map((item) => {
              return {
                ...item,
                name: translate(item.name),
              };
            })
            .filter((item) => item.id_parent == parentCategoryId);

          if (
            useGeneralStore().isOnboarding ||
            this.$route.path.includes("Onboarding")
          ) {
            this.categories.push({
              name: "Le tue survey sull'Onboarding",
            });
          }

          setTimeout(() => {
            this.$nextTick(() => {
              this.showList1 = true;
            });
          }, 1);
          // console.log('loadedcategories',this.categories);
        });
      }
    },
    loadChapters: async function () {
      const route = this.$route;
      const { id } = route.params;
      const parentCategoryId = route.params.parent ? route.params.parent : 0;
      this.parentCategoryId = parentCategoryId;
      this.chapters = null;
      await ApiService.get(
        "CampaignManager/GetUserLearningChapters/" +
          id +
          "?idCategory=" +
          parentCategoryId,
        {
          // params: {
          //   idCategoria: parentCategoryId
          // }
        }
      ).then((res) => {
        this.chapters = res.data.map((item) => {
          let x = {
            ...item,
            title: translate(item.title),
            description: translate(item.description),
            isClosed: !item.sbloccato,
          };
          x.liveInFuture = moment(item.data_ora_sessione_live) > moment();
          x.liveText = new IonicSafeString(
            this.$t("chapter.live_starting", {
              date: dateToDayMonth(item.data_ora_sessione_live),
              time: dateToHourMinute(item.data_ora_sessione_live),
            })
          ).value;
          return x;
        });
        console.log("loadedchapters", this.chapters);
      });
    },
    openChapter: function (chapter) {
      console.log("🚀 ", chapter);
      if (chapter.id_survey) {
        quizStore.setParentId(this.$route.params.id);
        quizStore.setParent(this.$route.params.parent);
        quizStore.setParentTitle(this.$route.params.title);

        this.$router.push({
          name: "campaign_quiz",
          params: {
            chapter: chapter.id,
            id: chapter.id_survey,
            title: chapter.title,
            parentId: this.$route.params.id,
            parent: this.$route.params.parent,
            parentTitle: this.$route.params.title,
          },
        });
        return;
      }
      // console.log('open',chapter);
      // this.$router.push({name:'campaign_quiz',params:{id:campaignId,parent:category.id}});
    },
  },

  computed: {
    testoPopover() {
      if (this.title == "I tuoi documenti digitali") {
        return "In questa area trovi i documenti che ti riguardano, potrai consultarli e firmali comodamente da questa applicazione per poi trovarteli sempre disponibili dentro al tuo archivio documentale.";
      } else if (this.title == "Piano di affiancamento") {
        return "Qui trovi il tuo piano di affiancamento composto da micropillole formative per le diverse aree e reparti. Alla fine di ogni percorso potrai fissare la data per l’affiancamento con il tuo collega e completare così la tua introduzione in azienda.";
      } else if (this.title == "Onboarding") {
        return "Qui trovi la tua area documentale, il tuo percorso di formazione base per l’inserimento e il tuo piano di affiancamento di incontri con  i tuoi colleghi.";
      } else if (!this.title) {
        return "In questa sezione trovi le informazioni e i materiali utili da scaricare, le domande più frequenti. Controlla i tuoi progress e completa i percorsi di Academy per ottenere dei premi!";
      } else {
        return "In questa sezione trovi le informazioni e i materiali utili da scaricare, le domande più frequenti. Controlla i tuoi progress e completa i percorsi di Academy per ottenere dei premi!";
      }
    },
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "$route.params.parent": function () {
      this.showList1 = false;
      this.loadCategories();
      this.loadChapters();
    },
  },
  ionViewWillEnter() {
    const route = this.$route;
    const { id } = route.params;
    this.showList1 = false;
    this.showList2 = false;
    this.campaignId = id;
    this.getCampaign(id).then((data) => {
      this.campaignData = data;
    });
    this.loadCategories();
    this.loadChapters();
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  //--background: var(--ion-color-primary-h);
  --background: var(--ion-color-primary-shade);
  color: var(--text-color-light);

  ion-card-title {
    color: var(--text-color-light);
  }

  ion-icon {
    font-size: 150%;
    margin: 0 5px -3px 0;
  }
}

//.status{
//  width:70%;
//  float:left;
//  font-size:70%;
//}
.percentage {
  width: 100%;
  float: left;
  text-align: right;
  font-weight: bold;
  color: var(--text-color-light);
}

.percentage-survey {
  text-align: right;
}

.lock-status {
  float: right;
  color: #fff;
}

.title {
  display: block;
  clear: both;
}

ion-card {
  ion-card-title {
    &.classtitle {
      text-align: center;
    }

    margin-top: 25px;
    margin-bottom: 5px;
  }

  ion-button {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  ion-card-content {
    text-align: center;
  }

  ion-button::part(native) {
    background: var(--text-color-light);
    color: var(--ion-color-primary);

    &:hover {
      background: var(--text-color-light);
    }
  }

  a.button {
    margin: 10px auto;
    display: inline-block;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary);
    padding: 10px;
    border-radius: var(--border-radius);
  }
}

.workshop {
  text-align: center;
  margin: 20px 0;

  & > div {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    padding-left: 35px;
  }

  & > div:before {
    content: "";
    position: absolute;
    left: 0;
    top: -5px;
    width: 30px;
    height: 32px;
    background: url("/assets/icon/play.png");
    background-size: 100% 100%;
  }
}

.islink {
  text-decoration: underline;
}

//.details{
//  width:100%;
//  clear:both;
//  h3 {
//    font-size: 110%;
//    color:var(--text-color-light);
//    font-weight: bold;
//    margin-bottom: 0px;
//  }
//  .period{
//    font-size:70%;
//  }
//}
.embed-video {
  margin: 15px;
}

.embed-video :deep(iframe) {
  max-width: 100%;
  aspect-ratio: 4 / 4;
}

.iframe-container {
  content: "Loading PDF...";
  background: #f0f0f0;
  width: 100%;
  height: 420px;
}
</style>
