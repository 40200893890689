import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";

export const useQuizStore = defineStore("quiz", () => {
  const _currentQuiz = ref(
    JSON.parse(localStorage.getItem("currentQuiz")) || []
  );
  const _currentQuestionIndex = ref(
    Number(localStorage.getItem("currentQuestionIndex")) || 0
  );
  const _quizState = ref(localStorage.getItem("quizState") || "ready");

  const _score = ref(Number(localStorage.getItem("score")) || 0);
  const _maxScore = ref(Number(localStorage.getItem("maxScore")) || 0);
  const _user = ref(JSON.parse(localStorage.getItem("user")) || null);

  const _parentId = ref(JSON.parse(localStorage.getItem("parentId")) || null);
  const _parent = ref(JSON.parse(localStorage.getItem("parent")) || null);
  const _parentTitle = ref(
    JSON.parse(localStorage.getItem("parentTitle")) || null
  );

  const currentQuiz = computed(() => _currentQuiz.value);
  const currentQuestion = computed(() => {
    return _currentQuiz.value[_currentQuestionIndex.value];
  });
  const currentQuestionIndex = computed(() => _currentQuestionIndex.value);
  const quizState = computed(() => _quizState.value);

  const score = computed(() => _score.value);
  const maxScore = computed(() => _maxScore.value);
  const user = computed(() => _user.value);

  const parentId = computed(() => _parentId.value);
  const parent = computed(() => _parent.value);
  const parentTitle = computed(() => _parentTitle.value);

  // Salva i dati nel local storage quando cambiano
  watch(
    () => [
      _currentQuiz,
      _currentQuestionIndex,
      _quizState,
      _score,
      _maxScore,
      _user,
      _parentId,
      _parent,
      _parentTitle,
    ],
    ([
      currentQuiz,
      currentQuestionIndex,
      quizState,
      score,
      maxScore,
      user,
      parentId,
      parent,
      parentTitle,
    ]) => {
      localStorage.setItem("currentQuiz", JSON.stringify(currentQuiz));
      localStorage.setItem("currentQuestionIndex", currentQuestionIndex);
      localStorage.setItem("quizState", quizState);
      localStorage.setItem("score", score);
      localStorage.setItem("maxScore", maxScore);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("parentId", JSON.stringify(parentId));
      localStorage.setItem("parent", JSON.stringify(parent));
      localStorage.setItem("parentTitle", JSON.stringify(parentTitle));
    },
    { deep: true } // Per osservare i cambiamenti in oggetti complessi come currentQuiz
  );

  function setCurrentQuiz(quiz) {
    _currentQuiz.value = quiz;
  }

  function nextQuestion(answer) {
    if (_currentQuestionIndex.value >= _currentQuiz.value.length - 1) {
      _currentQuiz.value[_currentQuestionIndex.value].answer = answer;
      _quizState.value = "finished";
      return;
    }
    _currentQuiz.value[_currentQuestionIndex.value].answer = answer;
    _currentQuestionIndex.value++;
  }

  function setScore(score) {
    _score.value = score;
  }

  function setMaxScore(maxScore) {
    _maxScore.value = maxScore;
  }

  function setUser(user) {
    _user.value = user;
  }

  function setParentId(id) {
    _parentId.value = id;
  }

  function setParent(parent) {
    _parent.value = parent;
  }

  function setParentTitle(title) {
    _parentTitle.value = title;
  }

  function restart() {
    _currentQuestionIndex.value = 0;
    _quizState.value = "ready";
    _score.value = 0;
    _maxScore.value = 0;
    _user.value = null;

    _parentId.value = null;
    _parent.value = null;
    _parentTitle.value = null;

    // Rimuovi i dati dal local storage quando si riavvia
    localStorage.removeItem("currentQuiz");
    localStorage.removeItem("currentQuestionIndex");
    localStorage.removeItem("quizState");
    localStorage.removeItem("score");
    localStorage.removeItem("maxScore");
    localStorage.removeItem("user");
    localStorage.removeItem("parentId");
    localStorage.removeItem("parent");
    localStorage.removeItem("parentTitle");
  }

  return {
    currentQuiz,
    currentQuestion,
    currentQuestionIndex,
    quizState,
    score,
    maxScore,
    user,

    parentId,
    parent,
    parentTitle,

    setCurrentQuiz,
    nextQuestion,
    setScore,
    setMaxScore,
    setUser,

    setParentId,
    setParent,
    setParentTitle,

    restart,
  };
});
